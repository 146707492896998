<template>
  <div>
    <RevLoadingScreen v-if="isLoading" text="loading" />

    <component
      :is="paymentResultComponent"
      v-if="!isLoading"
      class="bg-static-default-mid"
      :data-qa="`payment-result-${paymentStatus}`"
      :payment="paymentData"
    />

    <Trustmark />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, onErrorCaptured, onMounted, ref } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import Trustmark from '~/scopes/trustmark/Trustmark.vue'

import PaymentFailure from '../../components/Result/payment-error/PaymentFailure/PaymentFailure.vue'
import BouyguesPaymentSuccess from '../../components/Result/payment-success/BouyguesPaymentSuccess/BouyguesPaymentSuccess.vue'
import PaymentSuccess from '../../components/Result/payment-success/PaymentSuccess/PaymentSuccess.vue'
import useEnhanceLogError from '../../composables/useEnhancedLogError'
import { useCartStore } from '../../stores/cartStore'

import { usePaymentResultEventTracker } from './usePaymentResultEventTracker'

const cartStore = useCartStore()
const userStore = useUserStore()
const route = useRoute()
const errorLogger = useEnhanceLogError()

const { paymentId } = route.params

const {
  data: payment,
  error: paymentError,
  pending: isLoading,
} = paymentId
  ? await useHttpFetch(paymentAPI.getResult, {
      pathParams: { paymentId },
    })
  : { data: ref(), error: ref(), pending: false }

const paymentResult = ref(payment.value as PaymentResult)

const paymentResultComponent = computed(() => {
  if (!paymentId || paymentError.value) {
    return PaymentFailure
  }

  if (!paymentResult.value) {
    return null
  }
  const hasMobilePlan = paymentResult.value?.orders.some((order) =>
    order?.orderlines.some(
      (orderline) => !isEmpty(orderline.mobilePlan?.subscriptionId),
    ),
  )

  if (paymentResult.value.success && hasMobilePlan) {
    return BouyguesPaymentSuccess
  }

  return paymentResult.value.success ? PaymentSuccess : PaymentFailure
})

const paymentStatus = computed(() => {
  if (!paymentResult.value) {
    return 'loading'
  }

  return paymentResult.value.success ? 'success' : 'failure'
})

const paymentData = computed(() => {
  return {
    ...payment.value,
    errorMessage: payment.value?.errorMessage || paymentError.value?.message,
  }
})

onMounted(async () => {
  if (!paymentId || paymentError.value) {
    return
  }

  const isSuccess = paymentResult.value.success
  const track = usePaymentResultEventTracker(paymentResult.value)

  if (isSuccess) {
    track.paymentSuccess()
  } else {
    track.paymentFailure()
  }

  try {
    await cartStore.fetchCart()
    await userStore.fetchUser()
  } catch {
    // Empty catch until we tackle the following issue: CK-3553
  }
})

onErrorCaptured((err, instance, info) => {
  // eslint-disable-next-line no-underscore-dangle
  const componentTag = instance?.$options?.__name || 'none'

  errorLogger({
    component: {
      name: componentTag,
      props: { ...instance?.$props },
    },
    routeName: route.name,
    err,
    info,
  })

  return true
})
</script>
